<template>
  <div
    class="quizlet"
    :class="{ 'quizlet--auth-wall' : resultsRequireAuth }"
  >
    <div
      v-if="!suppressImg"
      class="quizlet__image-container"
    >
      <div
        ref="illustration"
        class="quizlet__icon"
        :class="{ 'quizlet__icon--animated': animationRunning }"
        v-html="svgMarkup"
      />
      <p
        v-if="showResults && !resultsRequireAuth"
        class="quizlet__results-preamble"
      >
        The correct answer is
      </p>
      <h2
        v-if="showResults && !resultsRequireAuth"
        class="quizlet__results-answer"
        data-test-id="quizlet-results-answer"
      >
        {{ correctAnswer }}
      </h2>
    </div>
    <quizlet-results
      v-if="showResults && !resultsRequireAuth"
      :correct-answer="correctAnswer"
      :answer-description="data.answerDescription"
      :cta-text="data.ctaText"
      :cta-link="data.ctaLink || ''"
      :show-cta="!resultsRequireAuth"
    />
    <engagement-result-auth-wall
      v-else-if="showResults && resultsRequireAuth"
      :engagement-type="data.type"
    />
    <div
      v-else
      class="quizlet__content-container"
    >
      <h2 class="quizlet__heading">
        Quick Quiz
      </h2>
      <p class="quizlet__question">
        {{ data.question | decodeHtml }}
      </p>
      <quizlet-form
        :options="data.options"
        :form-id="data.id"
        @optionSelected="changeToResults"
      />
    </div>
  </div>
</template>

<script>
import QuizletForm from '@/components/EngagementToolkit/QuizletForm.vue';
import QuizletResults from '@/components/EngagementToolkit/QuizletResults.vue';
import { GET_ENGAGEMENT } from '@/graphql/queries/engagement-toolkit-queries';
import EngagementResultAuthWall from '@/components/EngagementToolkit/EngagementResultAuthWall.vue';
import { mapGetters } from 'vuex';
import engagementToolkitMixin from '@/mixins/engagement-toolkit-mixin';

export default {
  name: 'QuizletComponent',
  components: {
    QuizletForm,
    QuizletResults,
    EngagementResultAuthWall,
  },
  mixins: [engagementToolkitMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    suppressImg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showResults: false,
      engagement: {},
      iconName: 'quizlet',
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
    ]),
    correctAnswer() {
      const correctOptionObject = this.data.options.filter((option) => option.isCorrect);
      return correctOptionObject[0].name;
    },
    resultsRequireAuth() {
      return this.engagement?.resultsRequireAuth && !this.userIsLoggedIn;
    },
  },
  mounted() {
    this.$apollo.queries.engagement.refetch();
  },
  methods: {
    changeToResults() {
      this.showResults = true;
    },
  },
  apollo: {
    // We need to make a query to get the engagement
    // userAnswers to keep track of if the user has
    // already answered this quizlet - so we can display
    // results instead of asking the same question.
    engagement: {
      query: GET_ENGAGEMENT,
      variables() {
        return {
          id: this.data.id,
          type: this.data.type,
        };
      },
      result({ data }) {
        if (data && data.engagement && data.engagement.userAnswers.length > 0) {
          this.changeToResults();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_quizlet';
</style>

<style lang="scss"> // no scoped to reach the svg elements within v-html
  // special stylesheet just for the animation
  @import '@/stylesheets/components/_quizlet-icon';
</style>
