<template>
  <form-component
    ref="quizletForm"
    class="quizlet-form"
    @submit.prevent="handleFormSubmit"
  >
    <div class="quizlet-form__options">
      <radio-button
        v-for="(option, index) in options"
        :id="`${formId}-${option.id}`"
        :key="index"
        :name="`${option.id}`"
        :value="option.name"
        :checked="selectedOptionId[0] === option.id"
        @input="handleOptionSelect(option.id)"
      >
        {{ option.name }}
      </radio-button>
    </div>
    <button
      ref="submit"
      type="submit"
      class="quizlet-form__submit-button button"
    >Submit</button>
  </form-component>
</template>

<script>
import RadioButton from '@/components/Inputs/RadioButton.vue';
import { ENGAGEMENT_VOTE } from '@/graphql/mutations/engagement-toolkit-mutations';

export default {
  name: 'QuizletForm',
  components: {
    RadioButton,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    formId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedOptionId: [],
    };
  },
  methods: {
    handleOptionSelect(id) {
      this.selectedOptionId = [id];
    },
    handleFormSubmit() {
      if (this.selectedOptionId.length === 0) {
        this.$store.dispatch('addToastNotification', {
          toastType: 'error',
          description: 'Please choose a valid poll answer.',
        });
        return;
      }
      this.$apollo.mutate({
        mutation: ENGAGEMENT_VOTE,
        variables: {
          engagementId: this.formId,
          optionIds: this.selectedOptionId,
        },
      }).then(() => {
        this.$emit('optionSelected');
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_quizlet-form';
</style>
