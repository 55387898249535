<template>
  <a
    :href="ctaLink"
    class="quizlet-results link--unstyled"
  >
    <p class="quizlet-results__preamble">
      The correct answer is
    </p>
    <h2 class="quizlet-results__answer">
      {{ correctAnswer }}
    </h2>
    <p
      v-if="showAnswerDescription"
      class="quizlet-results__description"
    >
      {{ answerDescription }}
    </p>
    <p v-if="showCta" class="quizlet-results__cta-text">
      {{ readMoreText }}
      <icon-component
        name="caret"
        title="caret icon"
        class="quizlet-results__cta-icon"
      />
    </p>
  </a>
</template>

<script>
export default {
  name: 'QuizletResults',
  props: {
    correctAnswer: {
      type: String,
      required: true,
    },
    answerDescription: {
      type: String,
      default: '',
    },
    ctaText: {
      type: String,
      default: '',
    },
    ctaLink: {
      type: String,
      default: '',
    },
    showCta: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    readMoreText() {
      if (this.ctaText) {
        return this.ctaText;
      }
      return 'Read More';
    },
    showAnswerDescription() {
      return this.answerDescription !== '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_quizlet-results';
</style>
